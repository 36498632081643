import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAttendances(ctx, data) {
      return useJwt.getAttendances(data).then(response => response);
    },

    exportAttendances(ctx, data) {
      return useJwt.exportAttendances(data).then(response => response);
    },

  },
};
