import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { getUserRole } from '@/auth/utils';
import attendanceStoreModule from '../attendanceStoreModule';

export default function useDrugStoreList() {
  const APARTMENT_STORE_MODULE_NAME = "attendance";

  // Register module
  if (!store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
    store.registerModule(APARTMENT_STORE_MODULE_NAME, attendanceStoreModule);
  }

  // UnRegister on leave
  // onUnmounted(() => {
  //   if (store.hasModule(APARTMENT_STORE_MODULE_NAME)) {
  //     store.unregisterModule(APARTMENT_STORE_MODULE_NAME);
  //   }
  // });
  // Use toast
  const toastification = toast();

  const blankItem = {
    name: '',
    province: null,
    district: null,
    ward: null,
    address: '',
    active: true,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'CSYT',
      field: 'medical_facility.name',
      data: 'medical_facility.name',
      sortable: false,
      hidden: getUserRole().name === 'medical_facility',
    },
    {
      label: 'Bác sĩ',
      field: 'doctor.full_name',
      data: 'doctor.full_name',
      sortable: false,
    },

    {
      label: 'Checkin',
      field: 'start_time',
      data: 'start_time',
      sortable: false,
    },
    {
      label: 'Checkout',
      field: 'end_time',
      // type: 'date',
      // dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      // dateOutputFormat: 'dd-MM-yyyy HH:mm',
      data: 'end_time',
      sortable: false,
    },
    {
      label: 'Thời gian online',
      field: 'online_time',
      data: '',
      sortable: false,
    },
    {
      label: 'Phiên khám',
      field: 'totalMedicalFiles',
      type: 'number',
      data: 'totalMedicalFiles',
      sortable: false,
    },

  ];
  const analytics = ref();
  const rows = ref([]);
  // filter
  const status = ref(null);
  const doctor = ref(null);
  const medicalFacility = ref(null);
  const startTime = ref(null);
  const endTime = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    skipCount: 0,
    maxResultCount: 10,
    medical_facility_id: null,
    doctor_id: null,
  });

  //   API Call

  const fetchDoctors = () => {
    store
      .dispatch('attendance/fetchAttendances', serverParams.value)
      .then(response => {
        const { items, totalCount } = response.data;
        totalRecords.value = totalCount;
        rows.value = items;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchData = () => {
    isLoading.value = true;
    fetchDoctors();
  };

  //   Methods
  const onEditItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ skipCount: (params.currentPage - 1) * serverParams.value.maxResultCount });
  };

  const onPerPageChange = params => {
    updateParams({ maxResultCount: params.currentPerPage });
  };

  const onSortChange = params => {
    const column = columns.map(_obj => _obj.field).indexOf(params[0].field);

    updateParams({
      order: [{
        dir: params[0].type,
        column,
      }],
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(doctor, val => {
    updateParams({ doctorId: val ? val._id : null });
  });
  watch(medicalFacility, val => {
    updateParams({ medical_facility_id: val ? val._id : null });
  });
  watch(startTime, val => {
    updateParams({ startTime: moment(val, 'DD-MM-YYYY').isValid() ? moment(val, 'DD-MM-YYYY').startOf('day').format() : null });
  });
  watch(endTime, val => {
    updateParams({ endTime: moment(val, 'DD-MM-YYYY').isValid() ? moment(val, 'DD-MM-YYYY').endOf('day').format() : null });
  });

  // UI
  const resolveStatusVariant = val => {
    if (val === true) return { variant: 'primary', title: "Kích hoạt", icon: 'SmileIcon' };
    return { variant: 'warning', title: "Chưa kích hoạt", icon: 'MehIcon' };
  };
  const resolveOnlineTime = val => {
    const startTimeObj = moment(val.start_time);
    const endTimeObj = moment(val.end_time);
    if (!startTimeObj.isValid() || !endTimeObj.isValid()) {
      return 'Không xác định';
    }
    const diff = endTimeObj.diff(startTimeObj, 'minutes');
    let remainMinutes = diff;
    const days = parseInt(diff / 1440, 10);
    remainMinutes %= 1440;
    const hours = parseInt(remainMinutes / 60, 10);
    remainMinutes %= 60;
    return `${days} ngày ${hours} giờ ${remainMinutes} phút`;
  };

  const resolveFamilyDoctorVariant = val => {
    if (val) return { variant: 'primary', title: "Có", icon: 'CheckIcon' };
    return { variant: 'danger', title: "Không", icon: 'MinusIcon' };
  };

  const resolveTime = val => {
    const time = moment(val);

    if (time.isValid()) {
      return time.format('DD/MM/YYYY HH:mm');
    }
    return "";
  };

  const resolveFilterLayout = () => {
    const role = getUserRole();
    if (role.name === 'medical_facility') {
      return 4;
    }
    return 3;
  };

  return {
    analytics,
    item,
    columns,
    rows,
    doctor,
    medicalFacility,
    startTime,
    endTime,
    status,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    resolveFilterLayout,
    fetchData,

    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,


    resolveStatusVariant,
    resolveFamilyDoctorVariant,
    resolveOnlineTime,
    resolveTime,
  };
}
