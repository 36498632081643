/* eslint-disable camelcase */
import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from "@/utils/toast";
import historyStoreModule from '../historyStoreModule';

export default function useHistoryModal(props) {
  const STORE_MODULE_NAME = 'history';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, historyStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModal = ref(null);

  const data = ref([]);
  const resetModal = () => {
    data.value = [];
  };

  const fetchData = () => {
    if (props.workLogId) {
      store.dispatch('history/getMeetingsForWorkLog', props.workLogId).then(response => {
        data.value = response.data.data;
      }).catch(error => {
        toastification.showToastError(error);
      });
    }
  };

  return {
    data,
    refModal,
    fetchData,
    resetModal,
  };
}
