<template>
  <div>
    <small class="text-danger pt-2">{{ error }}</small>
    <vue-good-table
      class="pt-2"
      :columns="columns"
      :rows="rows"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: is_represent -->
        <span v-if="props.column.field === 'patient'">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                rounded
                :src="`https://api.vntelehealth.com:8443/media/view?token=${encodeURIComponent(props.row.customer.avatar)}-100.webp`"
                size="48"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap text-secondary"
            >
              {{ props.row.customer.full_name }}
            </b-link>
            <small class="text-muted">{{ props.row.medical_examination_reason }}</small>
          </b-media>
        </span>
        <span v-else-if="props.column.field === 'status'">
          <b-badge
            pill
            :variant="resolveStatusVariant(props.row.status).variant"
            class="font-small-1"
          >
            {{ resolveStatusVariant(props.row.status).title }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'created_at' || props.column.field === 'end_at'">
          {{ parseDate(props.row[props.column.field]) }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">
          Không có bản ghi nào!
        </div>
      </div>
    </vue-good-table>

  </div>

</template>

<script>
import {
  BMedia,
  BAvatar,
  BBadge,
} from 'bootstrap-vue';

import { VueGoodTable } from 'vue-good-table';
import moment from 'moment';

export default {
  components: {
    BMedia,
    BAvatar,
    BBadge,

    VueGoodTable,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tenants: this.rows,
    };
  },

  watch: {
    rows(val) {
      this.tenants = val;
    },
  },

  methods: {
    parseDate(dateString) {
      return moment(dateString).format("DD-MM-YYYY HH:mm");
    },
  },

  setup() {
    // Table Handlers
    const columns = [
      {
        label: 'Bệnh nhân',
        field: 'patient',
        sortable: false,
      },
      {
        label: 'Bắt đầu',
        field: 'created_at',
        // type: 'date',
        // dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
        // dateOutputFormat: 'dd-MM-yyyy HH:mm',
      },
      {
        label: 'Kết thúc',
        field: 'end_at',
        // type: 'date',
        // dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
        // dateOutputFormat: 'dd-MM-yyyy HH:mm',
      },
      {
        label: 'Trạng thái',
        field: 'status',
        tdClass: 'text-center',
        sortable: false,
      },
    ];

    const resolveStatusVariant = val => {
      if (val === 'end') return { variant: 'primary', title: "Đã khám", icon: 'SmileIcon' };
      return { variant: 'warning', title: "Đã hủy", icon: 'MehIcon' };
    };

    return {
      columns,
      resolveStatusVariant,
    };
  },
};
</script>
