<template>
  <b-modal
    id="modal-history"
    ref="refModal"
    title="Phiên khám"
    ok-title="Đóng"
    size="lg"
    ok-only
    scrollable
    :hide-header-close="true"
    no-close-on-backdrop
    @show="fetchData"
    @hidden="resetModal"
  >
    <b-row>
      <b-col cols="12">
        <history-table :rows="data" />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue';

import { togglePasswordConfirmVisibility } from '@/layouts/mixins/forms';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import useHistoryModal from './useHistoryModal';
import HistoryTable from './HistoryTable.vue';

export default {
  components: {
    BModal,
    BCol,
    BRow,
    HistoryTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  props: {
    workLogId: {
      type: [String, null, undefined],
      required: true,
    },
  },

  setup(props, { emit }) {
    const {
      data,
      refModal,
      resetItemLocal,
      resetModal,

      fetchData,
    } = useHistoryModal(props, emit);

    return {
      data,
      refModal,
      resetItemLocal,
      resetModal,
      fetchData,

    };
  },
};
</script>
