var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('small',{staticClass:"text-danger pt-2"},[_vm._v(_vm._s(_vm.error))]),_c('vue-good-table',{staticClass:"pt-2",attrs:{"columns":_vm.columns,"rows":_vm.rows,"select-options":{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'patient')?_c('span',[_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"rounded":"","src":("https://api.vntelehealth.com:8443/media/view?token=" + (encodeURIComponent(props.row.customer.avatar)) + "-100.webp"),"size":"48"}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap text-secondary"},[_vm._v(" "+_vm._s(props.row.customer.full_name)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(props.row.medical_examination_reason))])],1)],1):(props.column.field === 'status')?_c('span',[_c('b-badge',{staticClass:"font-small-1",attrs:{"pill":"","variant":_vm.resolveStatusVariant(props.row.status).variant}},[_vm._v(" "+_vm._s(_vm.resolveStatusVariant(props.row.status).title)+" ")])],1):(props.column.field === 'created_at' || props.column.field === 'end_at')?_c('span',[_vm._v(" "+_vm._s(_vm.parseDate(props.row[props.column.field]))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v(" Không có bản ghi nào! ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }